import { AnalyticsService, AuthService, AuthState, DomService } from '$services';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, take } from 'rxjs';
import { LoginStorageData } from '../../routes/login/login.component';
import { Models } from '../../shared/models';
import { GoogleApiService } from '../../shared/services/google-api.service';
import { LocalStorageService, StorageKeys } from '../../shared/services/local-storage.service';
import { ApiService } from '../../shared/stores/api';
import { redirectToReturnUrlParam } from '../../shared/utils';
import { InputErrorShowCondition } from '../input-error/input-error.component';
import { googlePlacesToLocationObject } from '../../shared/stores';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showForgotPassword = true;
  @Input() showLogoutMessage = true;
  @Input() userName: string | null = null;

  public canResetAuthStatus = true;

  public errorCondition: InputErrorShowCondition = {
    touch: true,
    submit: true,
  };

  public formMain = this.fb.group({
    userName: [
      null,
      {
        validators: [Validators.required, Validators.pattern(Models.ValidationPatterns.PASSWORD)],
      },
    ],
    password: [null, [Validators.required]],
    remember: [null],
  });
  public waiting$ = new BehaviorSubject(false);
  public errorApi$ = new BehaviorSubject<any | null | undefined>(null);

  public authState$ = this.authService.authState$;
  public authState = AuthState;

  public showPassword = false;

  private userNameOnComponentInit?: string;

  private storage = this.localStorageService.getStorage<LoginStorageData>(StorageKeys.loginData);

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dom: DomService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private googleApi: GoogleApiService,
    private apiGlobal: ApiService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userName']) {
      this.updateUsername();
    }
  }

  ngOnInit() {
    this.syncLocalStorageWithFormControl();
    this.setAutocompleteOff();
  }

  ngOnDestroy() {
    if (this.canResetAuthStatus) {
      this.authService.authState = AuthState.initial;
    }
  }

  private syncLocalStorageWithFormControl() {
    const storageData = this.storage.getData();

    if (storageData) {
      this.userNameOnComponentInit = storageData.login.email;
    }

    if (this.hasUserCheckedRememberMeCheckbox(storageData)) {
      this.formMain.patchValue({ userName: storageData.login.email, remember: true });
    }
  }

  private hasUserCheckedRememberMeCheckbox(loginData: LoginStorageData): boolean {
    return loginData && loginData.login.remember;
  }

  private updateUsername() {
    if (this.userName) {
      this.formMain.patchValue({ userName: this.userName, password: null }, { emitEvent: false });
      this.formMain.markAsUntouched();
      this.formMain.markAsPristine();
    }
  }

  /**
   * Submit the form
   */
  public onLogin() {
    if (this.formMain.invalid) {
      return;
    }
    this.waiting$.next(true);
    this.errorApi$.next(null);

    this.saveLoginFormDataToStorage();

    // Authenticate
    const loginRequest = {
      Email: this.formMain.value.userName,
      Password: this.formMain.value.password,
    };

    combineLatest([this.authService.logIn(loginRequest), this.route.queryParams])
      .pipe(take(1))
      .subscribe(
        ([_auth, params]) => {
          this.canResetAuthStatus = false;
          this.saveGeographicInterestRegions();
          // Determine where to navigate on successful login
          // If there us a requested url, go to that one, otherwise go to previous url or homepage
          // Do not go to login page
          if (this.userNameOnComponentInit !== loginRequest.Email) {
            this.router.navigate(['/']);
          } else if (params['requestedUrl'] === '/login/' || params['currentUrl'] === '/login/') {
            this.router.navigate(['/']);
          } else if (params['requestedUrl']) {
            this.router.navigate([params['requestedUrl']]);
          } else if (params['currentUrl']?.includes('?searchState=')) {
            this.router.navigateByUrl(params['currentUrl']);
          } else if (params['currentUrl']) {
            this.router.navigate([decodeURIComponent(params['currentUrl'])], { queryParams: { target: params['target'] } });
          } else {
            this.router.navigate(['/']);
          }

          // this.redirectToReturnUrl();
          this.waiting$.next(false);
        },

        error => {
          this.analytics.gtag('event', 'login_fail');
          error.errorMsg = 'Error logging in.';
          if ((error.statusText = 'Unauthorized')) {
            error.errorMsg = 'Unable to log in, please check your username and password.';
          }

          this.errorApi$.next(error);
          this.waiting$.next(false);
        },
      );
  } // end onSubmit

  public redirectToReturnUrl() {
    redirectToReturnUrlParam(this.route, this.router);
  }

  private saveGeographicInterestRegions(): void {
    // Check localStorage for geoInterest
    const storedRegions = this.dom.localStorage?.getItem(Models.LocalStorageKeys.GeoInterest) || null;
    const regionsList = storedRegions ? JSON.parse(storedRegions) : '';
    const sendEmail = this.dom.localStorage?.getItem(Models.LocalStorageKeys.MarketingEmail) === 'Y' ? true : false;
    const sendText = this.dom.localStorage?.getItem(Models.LocalStorageKeys.TextMessages) === 'Y' ? true : false;
    // If any, loop through regions object
    if (regionsList) {
      for (const [placeId, placeName] of Object.entries(regionsList)) {
        if (regionsList.hasOwnProperty(placeId)) {
          // Per item, call google maps api to return a SearchString
          this.googleApi
            .getGooglePlaceDetails(placeId)
            .pipe(take(1))
            .subscribe(placeResult => {
              const searchName: string = typeof placeName === 'string' ? placeName : '';
              const queryParams = this.addQueryParams(placeResult);
              const location = googlePlacesToLocationObject(placeResult);
              // POST to saved-searches/add with payload per place
              const payload: Partial<Models.SavedSearch> = {
                SEARCHSTRING: '/' + Models.SearchRouteSlug + queryParams,
                SEARCHNAME: searchName,
                SAVEDSEARCHID: Math.floor(Math.random() * 100000000), // API does not return unique ID, need one for store
                EMAIL_ACTIVE: sendEmail,
                TEXT_ACTIVE: sendText,
                CITY: location?.city,
                STATE: location?.state,
                ZIP: location?.zip,
                ADDRESS: location?.propertyId,
                COUNTY: location?.county,
              };
              this.apiGlobal.savedSearches.post(payload).subscribe();
            });
        }
      }

      // Remove geographical areas of interest from localStorage
      this.dom.localStorage?.removeItem(Models.LocalStorageKeys.GeoInterest);
      this.dom.localStorage?.removeItem(Models.LocalStorageKeys.MarketingEmail);
      this.dom.localStorage?.removeItem(Models.LocalStorageKeys.TextMessages);
    }
  }

  private saveLoginFormDataToStorage(): void {
    this.storage.saveData({
      login: {
        email: this.formMain.value.userName,
        remember: this.formMain.value.remember || false,
      },
    });
  }

  private addQueryParams(placeResult: any) {
    const viewportBounds = placeResult.geometry?.viewport.toJSON();
    const queryParams = {
      map: {
        viewportBounds,
        pagination: { page: 1 },
        filters: { TYPE: [126, 127, 24, 115, 15, 8, 33, 106], CATEGORYLIST: [1, 2, 7, 3, 4, 6, 5], MINBEDS: null, MINBATHS: null, MORE: {} },
        search: { term: '', placeId: '' },
        contentView: 'searchResults',
      },
    };
    return `?searchState=${encodeURIComponent(JSON.stringify(queryParams))}`;
  }

  private setAutocompleteOff() {
    setTimeout(() => {
      const passwordElement = document.querySelector('p-password');
      const inputElement = passwordElement?.querySelector('input');
      if (inputElement) {
        inputElement.setAttribute('autocomplete', 'off');
      }
    });
  }
}
